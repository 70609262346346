import React from "react"
import classnames from "classnames"
import { useField } from "formik"

function Input({ label, className, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div className={classnames(className)}>
      <label className="block">
        {label && <span className="block text-gray-800">{label}</span>}
        <input
          {...field}
          {...props}
          className={classnames(
            "border-b-2 border-none mb-2 w-full bg-gray-100 border-transparent focus:border-transparent focus:ring-0",
            props.inputClassName
          )}
        />
        <hr
          className={classnames(props.inputClassName, {
            "border-1 cursor-pointer border-red-600":
              meta.touched && meta.error,
          })}
        ></hr>
      </label>
      {meta.touched && meta.error ? (
        <span className="text-xs text-red-600 font-medium leading-none">
          {meta.error}
        </span>
      ) : (
        <span className="text-xs font-medium leading-none">&nbsp;</span>
      )}
    </div>
  )
}

export default Input
