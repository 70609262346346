import React from "react"
import SectionTitle from "../SectionTitle"
import ContactUs from "./ContactUs"

function HowItWorks() {
  const data = [
    {
      stepNumber: "1",
      description:
        "Collaborate with our team to identify the features you want to implement, tailored to your business needs.",
    },
    {
      stepNumber: "2",
      description:
        "Choose from our wide range of professionally designed templates, or work with our team to create a custom design.",
    },
    {
      stepNumber: "3",
      description:
        "We handle the setup and configuration, including training you on how to use the platform.",
    },
    {
      stepNumber: "4",
      description:
        "Connect your existing domain or let us set up a new one for you.",
    },
    {
      stepNumber: "5",
      description:
        "Launch your ecommerce website with confidence and start selling your products or services online.",
    },
  ]

  return (
    <div className="relative py-8 md:py-12 lg:pt-12 lg:pb-16">
      <div className="container relative z-10">
        <SectionTitle title="How it Works?" excerpt="" />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
          <div className="pt-4">
            {data.map((step, index) => {
              return (
                <div key={index}>
                  <div className="flex flex-row items-center space-x-5 py-8 text-md md:text-xl">
                    <div className="">
                      <div className="flex justify-center text-center w-16 h-16 shrink-0 grow-0 rounded-full items-center bg-gray-400 text-gray-600">
                        {step.stepNumber}
                      </div>
                    </div>
                    <div className="">{step.description}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="mt-8" id="contact-us">
            <div className="bg-gray-100 shadow-md p-8 mx-0 md:lg-5 lg:mx-12">
              <div className="p-3">
                <div className="font-bold text-xl md:text-2xl">
                  Contact us
                </div>
                <div className="text-md pt-5 md:text-xl">
                  Contact us for a free one-hour consultation on how to leverage
                  your online presence and make it work for your business.
                  <div className="py-3">
                    Call us directly at{" "}
                    <span className="underline">+355 67 40 52 561</span>, or
                    fill out the form below and someone will get back to you
                    promptly.
                  </div>
                  <ContactUs />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-center md:mt-28 my-10 text-xl md:text-2xl mx-3 md:mx-10">
          <div>
            Our solution is a perfect fit for businesses in the
            <span className="underline"> retail</span>,{" "}
            <span className="underline"> multistore</span>,{" "}
            <span className="underline"> fashion and style</span>,{" "}
            <span className="underline"> electronics</span>,{" "}
            <span className="underline"> toys</span>,{" "}
            <span className="underline"> home equipment</span>,{" "}
            <span className="underline"> F&B</span>,{" "}
            <span className="underline"> sports equipment</span>, and{" "}
            <span className="underline">professional services industries</span>.
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
