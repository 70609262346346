import React from "react"
import SectionTitle from "../SectionTitle"

import ProductCatalogManagement from "../../svg/ecommerce/product-catalog-management.svg"
import ShoppingCart from "../../svg/ecommerce/shopping-cart.svg"
import PaymentGatewayIntegration from "../../svg/ecommerce/payment-gateway-integration.svg"
import CheckoutProcess from "../../svg/ecommerce/checkout-process.svg"
import OrderManagement from "../../svg/ecommerce/order-management.svg"
import ShippingOptions from "../../svg/ecommerce/shipping-options.svg"
import TaxCalculation from "../../svg/ecommerce/tax-calculation.svg"
import UserReviewsAndRatings from "../../svg/ecommerce/user-reviews-and-ratings.svg"
import CustomerAccounts from "../../svg/ecommerce/customer-accounts.svg"
import ProductRecommendations from "../../svg/ecommerce/product-recommendations.svg"
import GiftCardsAndCoupons from "../../svg/ecommerce/gift-cards-and-coupons.svg"
import InStorePickup from "../../svg/ecommerce/in-store-pickup.svg"

function WhatYouGet() {
  const icons = {
    ProductCatalogManagement: ProductCatalogManagement,
    ShoppingCart: ShoppingCart,
    PaymentGatewayIntegration: PaymentGatewayIntegration,
    CheckoutProcess: CheckoutProcess,
    OrderManagement: OrderManagement,
    ShippingOptions: ShippingOptions,
    TaxCalculation: TaxCalculation,
    UserReviewsAndRatings: UserReviewsAndRatings,
    CustomerAccounts: CustomerAccounts,
    ProductRecommendations: ProductRecommendations,
    GiftCardsAndCoupons: GiftCardsAndCoupons,
    InStorePickup: InStorePickup
  }

  const data = [
    {
      image: "ProductCatalogManagement",
      title: "Product Catalog Management",
      description: "organize and display your products on your website",
    },
    {
      image: "ShoppingCart",
      title: "Shopping Cart",
      description:
        "allow customers to select products and purchase them in a single transaction",
    },
    {
      image: "PaymentGatewayIntegration",
      title: "Payment Gateway Integration",
      description:
        "connect to a secure payment gateway to accept payments online",
    },
    {
      image: "CheckoutProcess",
      title: "Checkout Process",
      description:
        "simplify the checkout process to increase conversions and reduce abandoned carts",
    },
    {
      image: "OrderManagement",
      title: "Order Management",
      description:
        "manage customer orders, track shipments, and manage returns",
    },
    {
      image: "ShippingOptions",
      title: "Shipping Options",
      description:
        "offer a range of shipping options, including free shipping, flat rate, and real-time shipping rates",
    },
    {
      image: "TaxCalculation",
      title: "Tax Calculation",
      description:
        "automatically calculate taxes based on location and product type",
    },
    {
      image: "UserReviewsAndRatings",
      title: "User Reviews and Ratings",
      description:
        "allow customers to leave reviews and ratings for products they have purchased",
    },
    {
      image: "CustomerAccounts",
      title: "Customer Accounts",
      description:
        "allow customers to create and manage their own accounts on your website",
    },
    {
      image: "ProductRecommendations",
      title: "Product Recommendations",
      description:
        "recommend related products to customers based on their browsing and purchasing history",
    },
    {
      image: "GiftCardsAndCoupons",
      title: "Gift Cards and Coupons",
      description:
        "allow customers to purchase and redeem gift cards and coupons",
    },
    {
      image: "InStorePickup",
      title: "In-store pickup",
      description:
        "allow customers to purchase online and pickup in store",
    }
  ]

  const SingleData = ({ iconName, title, description }) => {
    const Icons = icons[iconName]
    return (
      <div className="flex flex-row space-x-12 ms:space-x-12">
        <div className="flex items-center justify-items-center">
          <Icons className="w-20 h-20 block " />
        </div>
        <div>
          <div className="text-md md:text-xl font-bold">{title}</div>
          <div className="text-md md:text-xl">{description}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="relative py-8 md:py-12">
      <div className="bg-gray-100 py-10">
        <div className="container relative z-10 ">
          <SectionTitle title="What you get"/>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-16">
            {data.map((data, index) => {
              return (
                <div key={index}>
                  <SingleData
                    iconName={data.image}
                    title={data.title}
                    description={data.description}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatYouGet
