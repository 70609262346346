import React, { useState } from "react"
import ArrowDown from "../../svg/arrow-down-faqs.svg"

function Faq() {
  const [allExpanded, setAllExpanded] = useState(false)
  const [questions, setQuestions] = useState([
    {
      question: "Can I transfer my current ecommerce site to your platform?",
      answer:
        "Chances are yes - we can migrate almost everything, including your products, customers, and order history.",
    },
    {
      question:
        "I have thousands of products. How can I import them without much hassle?",
      answer:
        "We have ready-made tools that make it easy to import all of your products to our platform without any pain.",
    },
    {
      question:
        "I want to connect my ecommerce site to my ERP system. Can you help?",
      answer:
        "Absolutely - we have hundreds of connectors and are more than happy to assist you with the implementation.",
    },
    {
      question:
        "My point of sale (POS) system is connected to my local bank, and I want to keep the same bank for my online sales. Can you accommodate this?",
      answer:
        "Yes - just connect us with your bank, and we'll handle the rest for you.",
    },
    {
      question:
        "I have multiple stores and want to offer 'pick up in store' as an option for my customers. Can you help?",
      answer:
        "We have you covered - we've heavily tested this component with more than 500 stores across Europe.",
    },
    {
      question:
        "I want to run different campaigns online with different pricing and configuration options. Is this possible?",
      answer:
        "Absolutely - you can configure your campaigns directly from your console.",
    },
  ])

  const toggleExpandAll = () => {
    setAllExpanded(!allExpanded)
    setQuestions(
      questions.map(q => {
        return { ...q, expanded: !allExpanded }
      })
    )
  }

  const toggleExpand = index => {
    setQuestions(
      questions.map((q, i) => {
        if (i === index) {
          return { ...q, expanded: !q.expanded }
        }
        return q
      })
    )
  }

  return (
    <div className="relative py-8 md:py-12 lg:pt-16 lg:pb-16">
      <div className="container relative z-10">
        <div className="flex justify-between md:items-center md:flex-row flex-col">
          <h2 className="text-4xl py-8 md:text-5xl font-bold leading-tight text-black">
            Find the answers you need
          </h2>
          <button
            className="flex justify-end text-right items-end text-black rounded-lg px-4 py-2"
            onClick={toggleExpandAll}
          >
            {allExpanded ? "Collapse All" : "Expand All"}
          </button>
        </div>

        <div className="py-8">
          {questions.map((q, index) => (
            <div key={index} className="py-2">
              <button
                className="flex w-full items-center justify-between text-left text-black border-b border-black py-5 cursor-pointer"
                onClick={() => toggleExpand(index)}
              >
                <div>
                  <h3 className="text-md md:text-xl font-semibold leading-7 px-5">
                    {q.question}
                  </h3>
                </div>
                <div>
                  <ArrowDown
                    className={`h-6 w-6 transform ${
                      q.expanded ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </button>
              {q.expanded && (
                <p className="bg-gray-100 px-5 py-4 text-md md:text-xl leading-7 text-black">
                  {q.answer}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Faq
