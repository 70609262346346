import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import SectionTitle from "../SectionTitle"

function ClientCard({ client }) {
  const img = (
    <GatsbyImage
      image={client.logo.childImageSharp.gatsbyImageData}
      className="w-full h-auto"
      alt={client.name}
    />
  )
  const className =
    "inline-block bg-white p-2 border hover:border-gray-700 transition duration-200 ease-in"

  if (client.url) {
    return (
      <a
        href={client.url}
        title={client.name}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {img}
      </a>
    )
  }

  return <div className={className}>{img}</div>
}

function Customers({ customers }) {
  return (
    <div className="relative py-8 md:py-10 lg:pt-10 lg:pb-20">
      <div className="container relative z-10">
        <SectionTitle title="Some of our customers" />
        <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-6 lg:grid-cols-6 gap-3 mt-10">
          {customers.edges.map(({ node }) => {
            return <ClientCard key={node.id} client={node} />
          })}
        </div>
      </div>
    </div>
  )
}

export default Customers
