import { Form, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import { sendEcommerceContactUsEmail } from "../../emails/emails"
import SecondaryInput from "../Form/SecondaryInput"

import PrimaryButton from "../PrimaryButton"
import * as notification from "../Form/notification"

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required("This field is required.").label("Name"),
  company: Yup.string().required("This field is required.").label("Company"),
  email: Yup.string()
    .email("Email address is not valid.")
    .required("This field is required.")
    .label("Email"),
})

function ContactUs() {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <div>
      <Formik
        initialValues={{ name: "", company: "", email: "" }}
        validationSchema={ContactUsSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            setIsLoading(true)
            const { data } = await sendEcommerceContactUsEmail(values)

            if (data.message) {
              notification.success(data.message)
            }
            setIsLoading(false)
            resetForm()
          } catch (error) {
            setIsLoading(false)
            notification.error("Something went wrong. Try again!")
          }
        }}
      >
        {formikProps => {
          return (
            <Form>
              <SecondaryInput
                name="name"
                type="text"
                label=""
                className="mt-5"
                placeholder="Your Name"
              />
              <SecondaryInput
                type="text"
                name="company"
                label=""
                className=""
                placeholder="Your Company"
              />
              <SecondaryInput
                name="email"
                type="email"
                label=""
                className=""
                placeholder="Your Email"
              />
              <div>
                <PrimaryButton disabled={isLoading} type="submit">
                  {isLoading ? "Loading..." : "get in touch"}
                </PrimaryButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ContactUs
