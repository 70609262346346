import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Customers from "../components/Ecommerce/Customers"
import EcommerceHero from "../components/Ecommerce/EcommerceHero"
import Faq from "../components/Ecommerce/FAQ"
import HowItWorks from "../components/Ecommerce/HowItWorks"
import WhatYouGet from "../components/Ecommerce/WhatYouGet"
import WhyUs from "../components/Ecommerce/WhyUs"
import GetStarted from "../components/GetStarted"

import Seo from "../components/seo"

const Ecommerce = () => {
  const data = useStaticQuery(graphql`
    query {
      allCustomersJson {
        edges {
          node {
            id
            name
            url
            logo {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)


  return (
    <>
      <Seo
        title="Enhance Your Online Store with Advanced Ecommerce Features"
        description="Discover the power of our cutting-edge ecommerce features to elevate your online store!"
      />
      <EcommerceHero />
      <HowItWorks />
      <WhatYouGet />
      <Customers customers={data.allCustomersJson} />
      <WhyUs />
      <Faq />
      <GetStarted />
    </>
  )
}

export default Ecommerce
