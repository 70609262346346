import React from "react"

function WhyUs() {
  return (
    <div className="relative py-8 md:py-12 lg:pt-12 lg:pb-16 bg-black">
      <div className="container relative z-10">
        <div>
          <h2 className="text-4xl md:text-5xl font-bold leading-tight text-white">
            Why us
          </h2>
        </div>
        <div className="my-6 text-white text-xl md:text-2xl">
          <p className="py-4">
            With over 10 years of experience, we've worked with businesses of
            all sizes, from small startups to enterprise-level corporations.
          </p>
          <p className="py-4">
            We've processed over 3.5 million orders and generated over 150
            million euros in revenue for our clients.
          </p>
          <p className="py-4">
            Our team has built hundreds of components and implemented hundreds
            of services, giving us a deep understanding of what works in
            ecommerce.
          </p>
          <p className="py-4">
            As Google Cloud certified partners and Salesforce B2C certified
            partners, we have the technical expertise to ensure your ecommerce
            site runs seamlessly.
          </p>
          <p className="py-4">
            We don't just talk the talk - we've successfully implemented
            ecommerce solutions for countless clients and are confident we can
            do the same for you.
          </p>
        </div>
      </div>
    </div>
  )
}
export default WhyUs
